// Pulling these images from Unsplash
// Toshi the dog from https://unsplash.com/@charlesdeluvio - what a funny dog...

.bg-gradient-primary {
  min-height: 100vh;
}

.bg-login-image {
  background: url($login-image);
  background-position: center;
  background-size: cover;
}

.bg-register-image {
  background: url($register-image);
  background-position: center;
  background-size: cover;
}

.bg-password-image {
  background: url($password-image);
  background-position: center;
  background-size: cover;
}

form.user {
  .custom-checkbox.small {
    label {
      line-height: 1.5rem;
    }
  }

  input {
    font-size: 0.8rem;
    padding: 1rem;
  }

  .btn-user {
    font-size: 0.8rem;
    padding: 0.75rem 1rem;
  }
}

.btn-google {
  @include button-variant($brand-google, $white);
}

.btn-facebook {
  @include button-variant($brand-facebook, $white);
}
