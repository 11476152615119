// Import Custom SB Admin 2 Variables (Overrides Default Bootstrap Variables)
@import "variables.scss";

// Import Bootstrap
@import "~bootstrap/scss/bootstrap";

// Import Custom SB Admin 2 Mixins and Components
@import "mixins.scss";
@import "global.scss";
@import "utilities.scss";

// Custom Components
@import "home.scss";
@import "dropdowns.scss";
@import "navs.scss";
@import "buttons.scss";
@import "cards.scss";
@import "charts.scss";
@import "login.scss";
@import "error.scss";
@import "footer.scss";

.block-line {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  svg {
    margin-right: 15px;
  }
  p {
    margin: 0;
  }
}
.f-10 {
  font-size: 10px;
}
.f-12 {
  font-size: 12px;
}

.lighter {
  font-weight: 400;
  text-transform: capitalize;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.middle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-change {
  font-size: 11px;
  right: 5px;
  width: 80px;
  margin-left: 2px;
  margin-right: 2px;
  svg {
    padding-right: 3px;
  }
}

.table-bordered th,
.table-bordered td {
  padding: 1rem 0.25rem;
}

.toast-confirm {
  max-width: 220px;
  margin: auto;
}

.block-page {
  max-width: 1140px;
  min-height: 93vh;
  padding: 75px 30px 30px 30px !important;
  background-color: rgb(255, 255, 255);
  margin: auto;
}

.form-label {
  margin: 0;
}
.form-control {
  margin-bottom: 7px;
}

.my-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.Toastify__toast--success {
  background-color: #021e3f;
  color: #fff;
}

.Toastify__toast--warning {
  background-color: #be3d30;
  color: #fff;
}

.form-control {
  margin-bottom: 10px;
}

.btn {
  color: #fff;
}

.btn-outline-primary {
  color: #021e3f;
}
.btn-outline-primary:hover {
  color: #fff;
}

.accordion-button {
  align-items: baseline !important;
}
