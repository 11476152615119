// MainPicture
.bg-home-image {
  height: 500px;
  position: relative;
  overflow: hidden;
  padding-top: calc(7rem + 72px);
  padding-bottom: 7rem;
  background-image: url(../images/header.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  a {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-weight: 600;
  }
  .masthead-content {
    z-index: 1;
    position: absolute;
    bottom: 200px;
    left: 50%;
    transform: translate(-50%);
  }
  .bg-circle {
    z-index: 0;
    position: absolute;
    border-radius: 100%;
    background: linear-gradient(0deg, #0b1426 0%, #021e3f 100%);
    opacity: 0.2;
  }
  .bg-circle-1 {
    height: 90rem;
    width: 90rem;
    bottom: -55rem;
    left: -55rem;
  }
  .bg-circle-2 {
    height: 50rem;
    width: 50rem;
    top: -25rem;
    right: -25rem;
  }
  .bg-circle-3 {
    height: 20rem;
    width: 20rem;
    bottom: -10rem;
    right: 5%;
  }
  .bg-circle-4 {
    height: 30rem;
    width: 30rem;
    top: -5rem;
    right: 35%;
  }
}

// Blockquote
.blockquote {
  padding: 100px 0;
  h1 {
    font-weight: 700;
    color: #021e3f;
    span {
      font-size: 0.8em;
    }
  }
}

.title-h {
  color: $blue;
  text-align: center;
  font-weight: 600;
  font-size: 1.3rem;
  span {
    color: $gray-500;
  }
}

.title-p {
  text-align: center;
  margin-bottom: 50px;
}

.section {
  padding: 100px 0;
}

.bg-grey {
  background-color: $gray-100;
}
